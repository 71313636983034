import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import Navbar from './components/Navbar';
import Home from './pages/Home';
import Wudu from './pages/Wudu';
import Salat from './pages/Salat';
import Pillars from './pages/Pillars';
import LoginPage from './pages/LoginPage'; // Make sure you have this page created
import AdminPage from './pages/Admin';
import FAQ from './pages/Faq';
import HadithPage from './pages/HadithPage'; // Import the HadithPage component
import Quran from "./Quran";
import SingleSurat from "./SingleSurat";
function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      setIsAuthenticated(!!user);
    });
    return () => unsubscribe(); // Cleanup subscription
  }, []);

  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/wudu" element={<Wudu />} />
        <Route path="/salat" element={<Salat />} />
        <Route path="/pillars" element={<Pillars />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/surat" element={<Quran />} />
        <Route path="/surat/:idsurat" element={<SingleSurat />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/admin" element={isAuthenticated ? <AdminPage /> : <Navigate to="/login" />} />
        <Route path="/hadith" element={<HadithPage />} /> {/* Add the HadithPage route */}
      </Routes>
    </Router>
  );
}

export default App;
