import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PrayerTimes from '../components/PrayerTimes';
import AyahOfTheDay from '../components/Aya';
import EventList from './EventList';
import EventCalendar from './EventCalendar'; // Import the EventCalendar component
import { db } from '../firebase-config';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import logo from '../images/logo.png';


const Home = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [viewMode, setViewMode] = useState('list');

  useEffect(() => {
    const unsubscribe = loadEvents();
    return () => unsubscribe();
  }, []);

  const loadEvents = () => {
    const eventsQuery = query(collection(db, 'events'), where('status', '==', 'approved'));
    return onSnapshot(eventsQuery, (snapshot) => {
      const eventsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        start: new Date(doc.data().date),
        end: new Date(doc.data().date),
      }));
      setEvents(eventsData);
    }, error => {
      console.error('Error fetching events:', error);
    });
  };

  const navigateTo = path => () => navigate(path);

  return (
    <div className="font-sans">
      <div className="text-white bg-gradient-to-r from-blue-800 to-blue-500 text-center p-12">
        <h1 className="text-5xl font-bold mb-4">Why Islam?</h1>
        <p className="max-w-xl mx-auto mb-8 text-lg">Discover the peace, beauty, and spirituality within Islam.</p>
        <AyahOfTheDay />
      </div>

      <div className="py-12 bg-gray-50">
        <div className="max-w-7xl mx-auto grid gap-8 grid-cols-1 md:grid-cols-3">
          <FeatureCard title="The Five Pillars of Islam" description="Learn about the foundation of Muslim life." onClick={navigateTo('/pillars')} image={logo} />
          <FeatureCard title="How to Pray" description="Understand the steps and significance of daily prayers." onClick={navigateTo('/salat')} image={logo} />
          <FeatureCard title="Purification Through Wudu" description="A guide to the Islamic ritual of purification." onClick={navigateTo('/wudu')} image={logo} />
        </div>
      </div>

      <PrayerTimes className="py-12" /> {/* Move PrayerTimes below the Feature Cards */}

      <div className="flex justify-center gap-4 my-8">
        <button onClick={() => setViewMode('list')} className={`px-4 py-2 rounded-md ${viewMode === 'list' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}>
          List View
        </button>
        <button onClick={() => setViewMode('calendar')} className={`px-4 py-2 rounded-md ${viewMode === 'calendar' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}>
          Calendar View
        </button>
      </div>

      {viewMode === 'list' && <EventList events={events} />} {/* Render the EventList component */}
      {viewMode === 'calendar' && <EventCalendar />} {/* Render the EventCalendar component */}

    </div>
  );
};

const FeatureCard = ({ title, description, onClick, image }) => (
  <div className="cursor-pointer rounded overflow-hidden shadow-lg hover:shadow-2xl transition duration-300 ease-in-out transform hover:-translate-y-1" onClick={onClick}>
    <img src={image} alt={title} className="w-full h-48 object-cover" />
    <div className="px-6 py-4">
      <h3 className="font-bold text-xl mb-2">{title}</h3>
      <p className="text-gray-700 text-base">{description}</p>
    </div>
  </div>
);

export default Home;
