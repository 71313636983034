import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Quran = () => {
  const [loading, setLoading] = useState(true);
  const [surahs, setSurahs] = useState([]);
  const [querySearch, setQuerySearch] = useState('');

  useEffect(() => {
    const fetchSurahs = async () => {
      try {
        const response = await fetch('https://api.alquran.cloud/v1/surah');
        const data = await response.json();
        setSurahs(data.data);
      } catch (error) {
        console.error("Failed to fetch surahs:", error);
      }
      setLoading(false);
    };

    fetchSurahs();
  }, []);

  return (
    <>
      <div className="text-white bg-gradient-to-r from-green-800 to-green-500 text-center p-12">
        <h1 className="text-5xl font-bold mb-4">The Holy Quran</h1>
        <p className="max-w-xl mx-auto mb-8 text-lg">Explore the divine wisdom of the Quran in a modern, user-friendly interface.</p>
        <input
          type="text"
          value={querySearch}
          onChange={(e) => setQuerySearch(e.target.value)}
          placeholder="Search Surahs..."
          className="input input-bordered input-lg w-full max-w-xs text-black"
        />
      </div>

      <div className="py-8 bg-gray-50">
        <div className="max-w-7xl mx-auto grid gap-8 grid-cols-1 md:grid-cols-3">
          {loading ? (
            [1, 2, 3, 4, 5].map((index) => (
              <div key={index} className="animate-pulse flex flex-col items-center p-4 bg-base-100 rounded-lg shadow">
                <div className="bg-gray-300 h-40 w-full mb-4 rounded"></div>
                <div className="h-4 bg-gray-300 w-3/4 mb-2 rounded"></div>
                <div className="h-4 bg-gray-300 w-1/2 rounded"></div>
              </div>
            ))
          ) : (
            surahs.filter(surah => !querySearch || surah.englishName.toLowerCase().includes(querySearch.toLowerCase())).map((surah) => (
              <Link key={surah.number} to={`/surat/${surah.number}`} className="card bg-base-100 rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300">
                <div className="card-body p-5">
                  <h2 className="card-title text-gray-900 font-semibold">{surah.englishName}</h2>
                  <p className="text-gray-600 text-sm">Number of Ayahs: {surah.numberOfAyahs}</p>
                </div>
              </Link>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default Quran;
