import React, { useState, useEffect } from 'react';
import { db, storage } from '../firebase-config';
import { collection, addDoc, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';


const Admin = () => {
  // State for FAQs management
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [sources, setSources] = useState([{ url: '' }]);
  const [imageUpload, setImageUpload] = useState(null);
  const [faqs, setFaqs] = useState([]);
  const [isEditing, setIsEditing] = useState('');

  // State for Events management
  const [events, setEvents] = useState([]);
  const [newEvent, setNewEvent] = useState({ name: '', date: '', startTime: '', endTime: '', description: '', location: '', rsvpLink: '', });
  const [isEditingEvent, setIsEditingEvent] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  useEffect(() => {
    const fetchFaqs = async () => {
      const querySnapshot = await getDocs(collection(db, "faqs"));
      setFaqs(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
    };

    fetchFaqs();
    
    const fetchEvents = async () => {
      const querySnapshot = await getDocs(collection(db, "events"));
      setEvents(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
    };

    fetchEvents();
  }, []);

  const handleEventChange = (e) => {
    const { name, value } = e.target;
    setNewEvent({ ...newEvent, [name]: value });
  };

  const handleEventSubmit = async (e) => {
    e.preventDefault();
    const imageUrl = await uploadImage();
    const eventData = { ...newEvent, imageUrl, rsvpLink: newEvent.rsvpLink };


    if (isEditingEvent) {
      await updateDoc(doc(db, "events", selectedEventId), eventData);
    } else {
      await addDoc(collection(db, "events"), eventData);
    }

    setNewEvent({ name: '', date: '', startTime: '', endTime: '', description: '', location: '' });
    setIsEditingEvent(false);
    setSelectedEventId(null);
    setImageUpload(null); // Clear image upload state
  };

  const handleEventEdit = (event) => {
    setIsEditingEvent(true);
    setSelectedEventId(event.id);
    setNewEvent({ ...event });
  };

  const handleEventDelete = async (id) => {
    await deleteDoc(doc(db, "events", id));
  };

  const handleSourceChange = (index, event) => {
    const newSources = [...sources];
    newSources[index] = { url: event.target.value };
    setSources(newSources);
  };

  const addSourceField = () => setSources([...sources, { url: '' }]);
  const removeSourceField = (index) => setSources(sources.filter((_, idx) => idx !== index));

  const handleImageUploadChange = (event) => {
    setImageUpload(event.target.files[0]);
  };

  const uploadImage = async () => {
    if (!imageUpload) return null;
    const imageRef = ref(storage, `images/${imageUpload.name}`);
    const snapshot = await uploadBytes(imageRef, imageUpload);
    const url = await getDownloadURL(snapshot.ref);
    return url;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const imageUrl = await uploadImage();
    const newFaq = {
      question,
      answer,
      sources: sources.map(s => s.url),
      images: imageUrl ? [imageUrl] : [],
    };

    if (isEditing) {
      await updateDoc(doc(db, "faqs", isEditing), newFaq);
    } else {
      await addDoc(collection(db, "faqs"), newFaq);
    }

    // Reset form
    setQuestion('');
    setAnswer('');
    setSources([{ url: '' }]);
    setImageUpload(null);
    setIsEditing('');
    // Refresh FAQ list
    const querySnapshot = await getDocs(collection(db, "faqs"));
    setFaqs(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
  };

  const handleEdit = (faq) => {
    setQuestion(faq.question);
    setAnswer(faq.answer);
    setSources(faq.sources.map(url => ({ url })));
    setIsEditing(faq.id);
  };

  const handleDelete = async (id) => {
    await deleteDoc(doc(db, "faqs", id));
    // Refresh FAQ list
    const querySnapshot = await getDocs(collection(db, "faqs"));
    setFaqs(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
  };

  return (
    <div className="p-8">
      <h1 className="text-2xl font-semibold mb-4">Admin Dashboard</h1>
      <form onSubmit={handleSubmit} className="mb-8">
        <div className="mb-4">
          <input
            type="text"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            placeholder="Question"
            className="input input-bordered w-full mb-3"
          />
        </div>
        <div className="mb-4">
          <textarea
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
            placeholder="Answer"
            className="textarea textarea-bordered w-full mb-3"
          />
        </div>
        {sources.map((source, index) => (
          <div key={index} className="flex items-center mb-2">
            <input
              type="text"
              value={source.url}
              onChange={(e) => handleSourceChange(index, e)}
              placeholder="Source URL"
              className="input input-bordered w-full mr-2"
            />
            {index > 0 && (
              <button
                type="button"
                onClick={() => removeSourceField(index)}
                className="btn btn-error btn-xs"
              >
                Remove
              </button>
            )}
          </div>
        ))}
        <div className="mb-4">
          <button
            type="button"
            onClick={addSourceField}
            className="btn btn-primary btn-sm"
          >
            Add Source
          </button>
        </div>
        <div className="mb-4">
          <input type="file" onChange={handleImageUploadChange} />
        </div>
        <button type="submit" className="btn btn-success">
          Submit
        </button>
      </form>
      
      {/* Event Management Section */}
      <div className="event-management mt-12">
        <h2 className="text-xl font-semibold mb-4">Event Management</h2>
        <form onSubmit={handleEventSubmit} className="mb-8 space-y-4">
          <input type="text" name="name" value={newEvent.name} onChange={handleEventChange} placeholder="Event Name" className="w-full p-2 border border-gray-300 rounded" />
          <input type="date" name="date" value={newEvent.date} onChange={handleEventChange} className="w-full p-2 border border-gray-300 rounded" />
          <input 
                        type="text" 
                        name="location" 
                        value={newEvent.location} 
                        onChange={handleEventChange} 
                        placeholder="Location" 
                        className="w-full p-2 border border-gray-300 rounded"
                    />
          <input type="time" name="startTime" value={newEvent.startTime} onChange={handleEventChange} placeholder="Start Time" className="w-full p-2 border border-gray-300 rounded" />
          <input type="time" name="endTime" value={newEvent.endTime} onChange={handleEventChange} placeholder="End Time" className="w-full p-2 border border-gray-300 rounded" />
          <textarea name="description" value={newEvent.description} onChange={handleEventChange} placeholder="Description" className="textarea textarea-bordered w-full"></textarea>
          <input 
  type="text" 
  name="rsvpLink" 
  value={newEvent.rsvpLink || ''} // Ensure you're handling the rsvpLink state correctly
  onChange={handleEventChange} 
  placeholder="RSVP Link" 
  className="w-full p-2 border border-gray-300 rounded"
/>
          <input type="file" onChange={handleImageUploadChange} />
          
          <button type="submit" className="btn btn-primary">{isEditingEvent ? 'Update Event' : 'Add Event'}</button>
        </form>

          {/* Displaying Events */}
          {events.map((event) => (
          <div key={event.id} className="event-item mb-4 p-4 border rounded shadow">
            <h3 className="text-lg font-semibold">{event.name}</h3>
            <p>Date: {event.date}</p>
            <p>Time: {event.startTime} - {event.endTime}</p>
            <p>Description: {event.description}</p>
            <p>Location: {event.location}</p>
            {event.rsvpLink && <a href={event.rsvpLink} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-success">RSVP</a>}
            <div className="mt-2">
              <button onClick={() => handleEventEdit(event)} className="btn btn-sm btn-warning mr-2">Edit</button>
              <button onClick={() => handleEventDelete(event.id)} className="btn btn-sm btn-error">Delete</button>
            </div>
          </div>
        ))}
      </div>
    
      {faqs.map(faq => (
        <div key={faq.id} className="mb-6 p-4 shadow rounded">
          <h3 className="font-bold text-lg mb-2">{faq.question}</h3>
          <p className="mb-2">{faq.answer}</p>
          {faq.sources && faq.sources.map((url, index) => <a key={index} href={url} className="link link-primary block">{url}</a>)}
          <div className="flex mt-4">
            <button onClick={() => handleEdit(faq)} className="btn btn-warning mr-2">
              Edit
            </button>
            <button onClick={() => handleDelete(faq.id)} className="btn btn-error">
              Delete
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Admin;
